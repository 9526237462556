import OfertaSezonowaPodwojna, { MoreInfo } from "../OfertaSezonowaPodwojna"
import React from "react"
import Snowfall from "react-snowfall"
import { Button, Col, Image, Row } from "react-bootstrap"
import "./style.scss"
import f1 from "../../images/oferty/wakacje2025/1.png"
import f2 from "../../images/oferty/wakacje2025/2.png"
import Card from "react-bootstrap/Card"

const imgStyle = { width: "calc(100% - 50px)", maxWidth: "1500px", borderRadius: "50px 50px 0 0" }
const imgStyle2 = { ...imgStyle, borderRadius: "0 0 50px 50px" }

const WakacjeOferty = () => (
  <>

    {/*<div className={"text-center mt-5"}>*/}
    {/*  <br/>*/}

    {/*  <h2 className="m-4 title text-uppercase">Letni obóz Taneczno-akrobatyczny</h2>*/}
    {/*  <p className="mx-5 subtitle">*/}
    {/*    Zapisz się (albo swoją pociechę, jeśli masz taką pod ręką) na półkolonie zimowe 10-14 lutego 2025! Kliknij*/}
    {/*    przycisk poniżej i dołącz do zabawy!*/}
    {/*  </p>*/}

    {/*  <Button*/}
    {/*    variant="primary" // Zmieniamy na klasyczny lub bardziej elegancki wariant*/}
    {/*    size="lg"*/}
    {/*    className="m-3 btn-custom" // Dodajemy własną klasę*/}
    {/*    href="https://forms.gle/KJJ2P4wL9EFhXVWD6"*/}
    {/*  >*/}
    {/*    Wypełnij formularz <br/> zgłoszeniowy na obóz! 🎉*/}
    {/*  </Button>*/}

    {/*  <Image src={f1} style={imgStyle}/>*/}
    {/*  <Image src={f2} style={imgStyle2}/>*/}
    {/*  <Button*/}
    {/*    variant="primary" // Zmieniamy na klasyczny lub bardziej elegancki wariant*/}
    {/*    size="lg"*/}
    {/*    className="m-3 btn-custom" // Dodajemy własną klasę*/}
    {/*    href="https://forms.gle/KJJ2P4wL9EFhXVWD6"*/}
    {/*  >*/}
    {/*    Wypełnij formularz <br/> zgłoszeniowy na obóz! 🎉*/}
    {/*  </Button>*/}

    {/*</div>*/}


    <div className={"text-center mt-5"}>
      <br/>
      <Card style={{borderRadius: 50,  margin: 30}}>
      <h2 className="m-4 title text-uppercase">Letni obóz Taneczno-akrobatyczny</h2>
      <p className="mx-5 subtitle">
        Z radością informujemy, że rozpoczynamy zapisy na <b>letni obóz taneczno-akrobatyczny</b>, który odbędzie się w
        malowniczym <b>Chmielnie w dniach 14–25 lipca 2025</b>.
        To wyjątkowa okazja dla naszych utalentowanych tancerzy i akrobatów, aby rozwijać swoje umiejętności, czerpać
        inspirację od doświadczonych instruktorów oraz
        spędzić niezapomniane chwile z rówieśnikami.
      </p>
      </Card>

      <Row style={{ marginLeft: 30, marginRight: 30,}}>
        <Col md={7} className="my-3 pl-0">
          <Card style={{ borderRadius: 50, height: "100%" }}>
            <h3 className="m-4 info-title text-uppercase">ℹ️ Najważniejsze informacje:</h3>
            <ul className="info-list text-left mx-5">
              <li>✔ <strong>Dla kogo?</strong> Dzieci i młodzież w wieku <b>7–19 lat</b>, trenujący na co dzień w
                grupach <b>Profil
                  Dance</b> i <b>AkroGim</b>.
              </li>
              <li>✔ <strong>Charakter obozu:</strong> Treningowo-rekreacyjny – intensywność zajęć dostosowana do wieku i
                poziomu uczestników. Planowany podział na 4 grupy treningowe.
              </li>
              <li>✔ <strong>Transport:</strong> Autokar</li>
              <li>✔ <strong>Wyżywienie:</strong> 4 posiłki dziennie:
                <ul>
                  <li>🍽 Śniadanie – bufet szwedzki</li>
                  <li>🍽 Dwudaniowy obiad</li>
                  <li>🍽 Podwieczorek</li>
                  <li>🍽 Kolacja – bufet szwedzki</li>
                </ul>
              </li>
              <li>✔ <strong>Cena: 2940 zł</strong> – obejmuje zakwaterowanie, wyżywienie, przejazd autokarem, zajęcia
                programowe, opiekę wychowawczą oraz ubezpieczenie NNW.
              </li>
            </ul>
          </Card>
        </Col>
        <Col md={5} className="my-3 pr-0">
          <Card style={{ borderRadius: 50, height: "100%" }}>
            <h3 className="m-4 info-title text-uppercase">🏆 Nasza kadra:</h3>
            <ul className="info-list text-left mx-5">
              <li>👩‍🏫 <strong>p. Ewa Konecka</strong> – kierownik obozu, instruktor tańca i akrobatyki</li>
              <li>💃 <strong>p. Wiktoria Sobocińska</strong> – wychowawca, instruktor tańca, pilatesu i jogi</li>
              <li>🤸 <strong>p. Krzysztof Ciszewski</strong> – trener akrobatyki sportowej</li>
              <li>💃 <strong>p. Aleksandra Emmel</strong> – wychowawca, instruktor tańca i akrobatyki</li>
              <li>💃 <strong>p. Dominika Blezień</strong> – wychowawca, instruktor tańca</li>
              <li>🤸 <strong>p. Inga Kobyłecka</strong> – wychowawca, trener akrobatyki i aerobiku sportowego</li>
            </ul>
          </Card>
        </Col>
      </Row>

      <Card style={{ borderRadius: 50, margin: 30}}>
        <Row>
          <Col md={6} className="mb-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <h3 className="m-4 info-title text-uppercase">📌 Formularz Zapisów:</h3>
              <p className="mx-5 subtitle">
                Aby zapisać dziecko na obóz, prosimy o wypełnienie formularza:
              </p>
            </div>
            <Button
              variant="primary"
              size="lg"
              className="btn-custom mt-auto mx-4"
              href="https://forms.gle/rBDo4DeB3GRwnEdD7"
            >
              Wypełnij Formularz zapisów 📝
            </Button>
          </Col>

          <Col md={6} className="mb-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <h3 className="m-4 info-title text-uppercase">📂 Dokumenty obozowe:</h3>
              <p className="mx-5 subtitle">
                {/*Program, Regulamin, Umowa i Karta Kwalifikacyjna Uczestnika - do wypełnienia:*/}
                Program i Regulamin Obozu, Umowa - do wypełnienia, Karta Kwalifikacyjna Uczestnika -do wypełnienia
              </p>
            </div>
            <Button
              variant="primary"
              size="lg"
              className="btn-custom mt-auto mx-4"
              href="https://drive.google.com/drive/folders/15NMX8X9xMjShZi_uho6yU6UPvs8NokSM?usp=sharing"
            >
              Pobierz Dokumenty obozowe 📂
            </Button>
          </Col>
        </Row>
      </Card>

      <Image src={f1} style={imgStyle}/>
      <Image src={f2} style={imgStyle2}/>

      <Row className="justify-content-center mt-4">
        <Col md={6} className="mb-3">
          <Button
            variant="primary"
            size="lg"
            className="btn-custom"
            href="https://forms.gle/rBDo4DeB3GRwnEdD7"
          >
            Wypełnij Formularz zapisów 📝
          </Button>
        </Col>
        <Col md={6} className="mb-3">
          <Button
            variant="primary"
            size="lg"
            className="btn-custom"
            href="https://drive.google.com/drive/folders/15NMX8X9xMjShZi_uho6yU6UPvs8NokSM?usp=sharing"
          >
            Pobierz Dokumenty obozowe 📂
          </Button>
        </Col>
      </Row>

    </div>


    {/*<div className={'text-center mt-5'}>*/}
    {/*  <Button variant={'brown'} size={"lg"}*/}
    {/*          href={"https://docs.google.com/forms/d/1KuJM684KEmo8M6Vbe_F5vcwvGSvnCipLgiRMwg26rkQ"}> WYPEŁNIJ FORMULARZ*/}
    {/*    ZGŁOSZENIOWY NA OBÓZ!!!</Button>*/}
    {/*  <br/>*/}
    {/*  <br/>*/}
    {/*  <Button variant={'violet'} size={"lg"}*/}
    {/*          href={"https://forms.gle/u7wgzczmkm3XrzSFA"}> WYPEŁNIJ FORMULARZ*/}
    {/*    ZGŁOSZENIOWY NA WARSZTATY!!!</Button>*/}
    {/*</div>*/}
    {/*<OfertaSezonowaPodwojna*/}
    {/*  title={""}*/}
    {/*  offers={[{*/}
    {/*    name: "Obóz", src: require("../../images/oferty/Obóz 2024 - ulotka.jpg"),*/}
    {/*    description: "Wakacje 2024  14 - 27 lipca",*/}
    {/*    subdescription: "",*/}
    {/*    title: "OBÓZ TANECZNO - AKROBATYCZNY w Chmielnie 14-27 lipca 2024",*/}
    {/*    pdf: require("../../images/oferty/Obóz 2024 - ulotka_compressed.pdf"),*/}
    {/*    url: 'https://docs.google.com/forms/d/1KuJM684KEmo8M6Vbe_F5vcwvGSvnCipLgiRMwg26rkQ'*/}

    {/*  }*/}
    {/*    ,*/}
    {/*    {*/}
    {/*      name: "Warsztaty", src: require("../../images/oferty/lato 2024 - acro dance art & fun.jpg"),*/}
    {/*      description: "Letnie warsztaty taneczno-artystyczne 19-23 sierpnia 2024", subdescription: "",*/}
    {/*      // pdf: require("../../images/oferty/lato 2024warsztaty.pdf"),*/}
    {/*      pdf: require("../../images/oferty/letnie_warsztaty2024_profildance.pdf"),*/}
    {/*      // pdf: require("../../images/oferty/Letnie WARSZTATY DLA DZIECI ORGANIZOWANE PRZEZ PROFIL DANCE.docx_compressed.pdf"),*/}
    {/*      // pdf2: require("../../images/oferty/lato 2024 - acro dance art & fun_compressed.pdf"),*/}
    {/*      url: 'https://forms.gle/u7wgzczmkm3XrzSFA'*/}
    {/*    },*/}
    {/*  ]}*/}
    {/*  // hideIkonkaDoKlikania={true}*/}
    {/*></OfertaSezonowaPodwojna>*/}
    {/*<div className={'text-center mt-1 mb-1'}>*/}
    {/*  <Button variant={'brown'} size={"lg"}*/}
    {/*          href={"https://docs.google.com/forms/d/1KuJM684KEmo8M6Vbe_F5vcwvGSvnCipLgiRMwg26rkQ"}> WYPEŁNIJ FORMULARZ*/}
    {/*    ZGŁOSZENIOWY NA OBÓZ!!!</Button>*/}
    {/*  <br/>*/}
    {/*  <br/>*/}
    {/*  <Button variant={'violet'} size={"lg"}*/}
    {/*          href={"https://forms.gle/u7wgzczmkm3XrzSFA"}> WYPEŁNIJ FORMULARZ*/}
    {/*    ZGŁOSZENIOWY NA WARSZTATY!!!</Button>*/}
    {/*</div>*/}
    {/*<p>Wakacyjny obóz akrobatyczno-taneczny odbędzie się w terminie 14-27 lipca 2024. Szczegóły już wkrótce</p>*/}
    {/*<p>Letnie warsztaty stacjonarne odbędą się w terminie 19-23 sierpnia 2024</p>*/}
    {/*    <OfertaSezonowaPodwojna
      title={"Wakacje 2023"}
      offers={[{
        name: "Taneczne lato 2023", src: require("../../images/oferty/lato_2023_warsztaty.png"),
        description: "Letnie warsztaty taneczno-artystyczne, 21-25 sierpnia 2023", subdescription: "",
        pdf: require("../../images/oferty/lato-2023-acro-dance-art-fun-1.pdf"),
      },
        {
          name: "Obóz 2023", src: require("../../images/oferty/oboz2023.png"),
          description: "Chmielno, 16-29 lipca 2023", subdescription: "",
          pdf: require("../../images/oferty/Obóz-2023.pdf"),
        }
      ]}
      moreInfoFile={require("../../images/oferty/LETNIE-WARSZTATY-DLA-DZIECI-ORGANIZOWANE-PRZEZ-PROFIL-DANCE.pdf")}
    ></OfertaSezonowaPodwojna>
    <MoreInfo title={"UMOWA OBÓZ 23"} moreInfoFile={require("../../images/oferty/umowa-obóz-K-23.pdf")} pages={2}/>
    <MoreInfo title={"Karta kwalifikacyjna uczestnika wypoczynku"} moreInfoFile={require("../../images/oferty/Karta-kwalifikacyjna-uczestnika-wypoczynku.pdf")} pages={2}/>
  */}
  </>
)

export default WakacjeOferty
